import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import HeaderNavLink from "@atoms/primary_link.tsx/PrimaryLink";
import SelectLanguage from "@molecules/SelectLanguage";
import { usePathname } from "@helper/use_pathname";

import MenuSideBar from "./MenuSideBar";

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = usePathname();

  const [showNavigationSidebar, setNavigationSidebarVisibility] =
    useState<boolean>(false);

  const navLinkIsBlack = pathname === "impressum" || pathname === "privacy";

  return (
    <header className="z-20 absolute w-full top-0 flex items-center justify-between p-5">
      <div className="flex items-center">
        <Link to="/">
          <div className="w-28 h-28 mr-5">
            <StaticImage
              placeholder="none"
              src="../../assets/images/baraka-logo-transparent.png"
              alt="Baraka Logo Transparent"
              layout="fullWidth"
              objectFit="contain"
            />
          </div>
        </Link>

        <div className="hidden md:flex md:items-center">
          <div className="px-6">
            <HeaderNavLink
              color={navLinkIsBlack ? "black" : "white"}
              isActive={pathname === "/"}
              href="/"
            >
              {t("Home")}
            </HeaderNavLink>
          </div>
          <div className="px-6">
            <HeaderNavLink
              color={navLinkIsBlack ? "black" : "white"}
              isActive={pathname === "about-us"}
              href="/about-us"
            >
              {t("About Us")}
            </HeaderNavLink>
          </div>
          <div className="px-6">
            <HeaderNavLink
              color={navLinkIsBlack ? "black" : "white"}
              isActive={pathname === "products"}
              href="/products"
            >
              {t("Products")}
            </HeaderNavLink>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-end w-1/2 font-bold">
        <div className="hidden md:block md:w-44">
          <a
            href="mailto:info@baraka-grosshandel.de"
            style={{
              backgroundColor: "#F7A600",
              paddingTop: "7px",
              paddingBottom: "9px",
            }}
            className="text-white text-center w-full px-8 rounded-3xl"
          >
            {t("Contact")}
          </a>
        </div>

        <div className="md:mr-4">
          <SelectLanguage />
        </div>

        <div>
          <FaBars
            className="text-3xl block md:hidden cursor-pointer"
            onClick={() => setNavigationSidebarVisibility(true)}
          />
        </div>
      </div>
      {showNavigationSidebar && (
        <MenuSideBar setVisibility={setNavigationSidebarVisibility} />
      )}
    </header>
  );
};

export default Header;
