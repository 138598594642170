import React from "react";
import Header from "@organisms/Header";
import Footer from "@organisms/Footer";
import { graphql } from "gatsby";
type Props = {};

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <div className="flex flex-col w-full h-full">
      <Header />
      <div className="flex-1">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
