import React from "react";
import { Link } from "gatsby-plugin-react-i18next";

type Props = {
  href: string;
};

const SecondaryLink: React.FC<Props> = ({ children, href }) => {
  return (
    <Link to={href} className="px-4 opacity-50 hover:opacity-100">
      {children}
    </Link>
  );
};

export default SecondaryLink;
