import React from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import SidebarNavLink from "@atoms/SidebarNavLink";
import { usePathname } from "@helper/use_pathname";

type Props = {
  setVisibility: (e: boolean) => void;
};

const MenuSideBar: React.FC<Props> = ({ setVisibility }) => {
  const { t } = useTranslation("header");
  const { pathname } = usePathname();

  return (
    <div
      style={{
        backgroundColor: "#282828",
        width: "100vw",
        height: "100vh",
      }}
      className="fixed z-10 top-0 bottom-0 right-0 bg-gray-600 p-2"
    >
      <div className="flex justify-end mb-10 p-5">
        <FaTimes
          onClick={() => setVisibility(false)}
          className="text-2xl cursor-pointer"
          fill="white"
        />
      </div>

      <div className="flex flex-col pl-6">
        <SidebarNavLink isActive={pathname === "/"} to="/">
          {t("Home")}
        </SidebarNavLink>

        <SidebarNavLink isActive={pathname === "products"} to="/products">
          {t("Products")}
        </SidebarNavLink>

        <SidebarNavLink isActive={pathname === "about-us"} to="/about-us">
          {t("About Us")}
        </SidebarNavLink>

        <SidebarNavLink isActive={pathname === "impressum"} to="/impressum">
          {t("Imprint")}
        </SidebarNavLink>

        <SidebarNavLink isActive={pathname === "privacy"} to="/privacy">
          {t("Privacy")}
        </SidebarNavLink>
      </div>
    </div>
  );
};

export default MenuSideBar;
