import { useEffect, useState } from "react";

export const usePathname = () => {
  const [pathname, setPathname] = useState<string>("");

  const removeEmtpyStringFromTheList = (list: string[]) => {
    const res = [...list];
    res.forEach((str) => {
      if (str.trim().length !== 0) res.push(str);
    });
    return res;
  };

  useEffect(() => {
    const a = window.location.href.split("/");
    if (a[a.length - 1].length === 0) setPathname("/");
    else setPathname(`${a[a.length - 1]}`);
  }, []);

  return { pathname };
};
