import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import * as styles from "./PrimaryLink.module.css";

type Props = {
  href: string;
  isActive?: boolean;
  color?: "black" | "white";
};

const PrimaryLink: React.FC<Props> = ({
  children,
  href,
  isActive = false,
  color = "black",
}) => {
  const c = color === "black" ? "text-black" : "text-white";

  return (
    <Link className={styles.link} to={href}>
      <span
        className={`font-bold uppercase border-b-2 border-transparent ${c}`}
      >
        {children}
      </span>
      <div
        style={{
          backgroundColor: color,
        }}
        className={
          !isActive ? styles.underlineAnim : styles.underlineWithoutHover
        }
      />
    </Link>
  );
};

export default PrimaryLink;
