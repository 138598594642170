import { StaticImage } from "gatsby-plugin-image";
import {
  useI18next,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next/dist";
import React, { useState } from "react";
import { FaChevronUp } from "react-icons/fa";

type IconImageProps = {
  lang: string;
};
const IconImageBasedOnLanguage: React.FC<IconImageProps> = ({ lang }) => {
  switch (lang) {
    case "en":
      return (
        <StaticImage
          width={32}
          height={32}
          placeholder="none"
          src="../../assets/images/united-kingdom.png"
          alt="United Kingdom Flag"
        />
      );
    case "fr":
      return (
        <StaticImage
          width={32}
          height={32}
          placeholder="none"
          src="../../assets/images/france.png"
          alt="France Flag"
        />
      );
    case "de":
      return (
        <StaticImage
          width={32}
          height={32}
          placeholder="none"
          src="../../assets/images/germany.png"
          alt="Germany Flag"
        />
      );
    default:
      throw new Error(`This lang '${lang}' doesn't exist`);
  }
};

const SelectLanguage = () => {
  const { languages, originalPath, changeLanguage } = useI18next();
  const [isOpen, setDopdownOpenness] = useState(false);
  const { i18n } = useTranslation();

  const removeSlashAtEndOfOriginalPath = () => {
    const splitBySlash = originalPath.split("/");
    if (splitBySlash.length < 3) return "/";

    return `/${splitBySlash[1]}`;
  };

  const newPath = removeSlashAtEndOfOriginalPath();

  return (
    <div className="relative">
      <button
        onClick={() => setDopdownOpenness((visibility) => !visibility)}
        className="flex justify-center items-center w-32 mb-1"
      >
        <IconImageBasedOnLanguage lang={i18n.language} />
        <p className="pr-2 pl-1 font-semibold">{i18n.language.toUpperCase()}</p>

        <FaChevronUp
          style={{
            transform: isOpen ? "rotate(0)" : "rotate(180deg)",
          }}
          className="transition duration-150 ease-in-out"
        />
      </button>
      {isOpen && (
        <div className="absolute shadow-xl bg-white w-32 mt-1">
          {languages.map((lang) => {
            return (
              <div
                style={{
                  padding: "0 20px",
                  backgroundColor:
                    i18n.language === lang.toLowerCase()
                      ? "rgba(229, 231, 235)"
                      : "transparent",
                }}
                className="flex items-center justify-center w-full cursor-pointer hover:bg-gray-400 h-10"
                onClick={() => {
                  console.log("Change Language");
                  changeLanguage(lang, newPath);
                }}
                key={lang}
              >
                <div className="mr-4">
                  <IconImageBasedOnLanguage lang={lang} />
                </div>
                <Link
                  className="text-center text-lg uppercase"
                  to={newPath}
                  language={lang}
                >
                  {lang}
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectLanguage;
