import React from "react";
import { Link } from "gatsby-plugin-react-i18next";

type Props = {
  to: string;
  isActive: boolean;
};

const SidebarNavLink: React.FC<Props> = ({ isActive, to, children }) => {
  return (
    <div className="flex justify-start">
      <Link
        style={{
          borderBottom: isActive ? "3px solid white" : "",
        }}
        className="font-bold text-white text-2xl curor-pointer mb-8"
        to={to}
      >
        {children}
      </Link>
    </div>
  );
};
export default SidebarNavLink;
