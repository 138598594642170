import SecondaryLink from "@atoms/SecondaryLink";
import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import SidebarNavLink from "@atoms/SidebarNavLink";

const Footer = () => {
  const { t } = useTranslation("footer");
  const { t: tHeader } = useTranslation("header");
  return (
    <footer
      style={{
        backgroundColor: "#5A5A63",
      }}
      className="text-white pb-4 pt-14 px-5 md:px-10"
    >
      <div className="flex items-start justify-between mb-3">
        <div className="mb-5">
          <div className="w-24 h-24 mb-5">
            <StaticImage
              placeholder="none"
              src="../../assets/images/baraka-logo-white.png"
              alt="Baraka Logo Transparent"
              layout="fullWidth"
              objectFit="contain"
            />
          </div>
          <p className="font-bold">BARAKA GmbH</p>
          <p>{t("in")} Kerpen</p>
          <p>Germany</p>
        </div>
        <div className="hidden md:block">
          <SidebarNavLink isActive={false} to={"/"}>
            {tHeader("Home")}
          </SidebarNavLink>
          <SidebarNavLink isActive={false} to={"/about-us"}>
            {tHeader("About Us")}
          </SidebarNavLink>
          <SidebarNavLink isActive={false} to={"/products"}>
            {tHeader("Products")}
          </SidebarNavLink>
        </div>
      </div>

      <h3 className="text-white">{t("h2")}</h3>
      <div style={{ height: 1 }} className="bg-white w-full my-5 md:my-8"></div>
      <div className="mb-5 flex justify-between">
        <p className="text-sm opacity-50">
          ©2021 BARAKA GmbH<br /> {t("Alle Rechte vorbehalten")}
        </p>

        <div>
          <SecondaryLink href="/impressum">{t("Impressum")}</SecondaryLink>
          <SecondaryLink href="/privacy">{t("Datenschutz")}</SecondaryLink>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
